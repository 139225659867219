<template>
  <div class="team-container">
    <Header></Header>

    <div class="team-content">
      <div class="top-image">
        <div class="title">Terms & Conditions</div>
      </div>
      <div class="team-list">
        <div class="team-items">
          <div class="title">The first related definition</div>
          <div class="description">
            Recharge card transfer: The term “recharge card transfer” mentioned
            in this agreement refers to the recharge card transfer service
            provided by Giftcard8. The user can transfer the recharge card he
            holds to the giftcard8 partner merchant on the giftcard8 website,
            and the transferred amount will be directly transferred to the
            user’s giftcard8 account. The specific supported types of
            rechargeable cards are subject to the page prompts when users use
            this service.
          </div>
        </div>
        <div class="team-items">
          <div class="title">
            Registration Information and Privacy Protection
          </div>
          <div class="description">
            1.The ownership of the account on this website (ie the user account
            on this website) is reset to zero on this website.After the user
            completes the registration application procedures, the user obtains
            the right to use the account on this website. Users should provide
            timely, detailed and accurate personal information, and constantly
            update the registration information to meet timely, detailed and
            accurate requirements. All the original information entered will be
            cited as registration information. This website does not take any
            responsibility for problems caused by untrue registration
            information, and the consequences of the problem.
          </div>
          <div class="description">
            2.The user transfers, sells or lends his account and password to
            others for use. If the user authorizes others to use the account, he
            shall be fully responsible for all actions of the authorized person
            under the account.
          </div>
          <div class="description">
            <div>
              3.This website may disclose your information under the following
              circumstances:
            </div>
            <div>(1)Obtain your authorization in advance;</div>
            <div>
              (2)According to the requirements of laws, regulations, legal
              procedures or mandatory requirements of the competent government
              departments;
            </div>
            <div>
              (3)To safeguard the legitimate rights and interests of this
              website, such as finding, preventing, and handling fraud or
              security issues;
            </div>
            <div>
              (4)Comply with the relevant service terms or use agreement.
            </div>
          </div>
          <div class="description">
            4.In order to provide better services to users, you agree that this
            website will send you relevant commercial service information
            through SMS and other forms.
          </div>
          <div class="description">
            <div>
              5.Understanding and recognition of certification services
            </div>
            <div>
              (1)The authentication service is a certain kind of identification
              service provided by the owner of this website. Unless otherwise
              agreed in this agreement, once your platform account has been
              authenticated, the corresponding identity information and
              authentication results will not be modified or cancelled for any
              reason; if your identity information is completed authentication
              and the corresponding authentication information of the giftcard8
              account.
            </div>
            <div>
              (2)The authentication services provided by the company to you
              include the following specific procedures: 1. Bank account
              identification 2. Identity information identification 3. Mobile
              phone number identification. The company has the right to record
              and save the information and information you provide to the
              company after completing the above procedures. The result
              information obtained by the company also has the right to provide
              you or a third party with a record of whether you have passed the
              authentication and your identity information in accordance with
              the provisions of this agreement.
            </div>
          </div>
        </div>
        <div class="team-items">
          <div class="title">Disclaimers and Liability Limitation</div>
          <div class="description">
            1.The content of this agreement includes the main text of the
            agreement and the rules for the use of Giftcard8 services that
            Giftcard8 has released or may release in the future. All rules are
            an integral part of this agreement and have the same legal effect as
            the body of the agreement.
          </div>
          <div class="description">
            2.For matters not covered in this agreement, the “Giftcard8 Service
            Agreement” and other relevant rules published on the Giftcard8
            website shall prevail.
          </div>
          <div class="description">
            3.The failure of this website to exercise or implement any rights or
            provisions of this service agreement does not constitute a waiver of
            the aforementioned rights or rights.
          </div>
          <div class="description">
            4.If any clause in this agreement is completely or partially invalid
            or not enforceable for whatever reason, the remaining clauses in
            this agreement shall still be valid and binding.
          </div>
          <div class="description">
            5.As you inform others of your Giftcard8 account password or fail to
            keep your own password, or share your Giftcard8 account with others
            or any other faults not on this website, your personal information
            is leaked, which has nothing to do with this website.
          </div>
          <div class="description">
            6. Anything due to hacker attack, computer virus intrusion or
            attack, the influence caused by the technical adjustment of the
            telecommunications sector, temporary closure due to government
            control, due to third-party reasons (including force majeure, such
            as fire, flood, lightning, earthquake, flood, Typhoons, tornadoes,
            volcanic eruptions, plagues and epidemics of infectious diseases,
            strikes, wars or violent acts or similar incidents, etc.) and other
            certification information leakage, loss, theft or tampering caused
            by the company’s fault, etc. Site has nothing to do.
          </div>
          <div class="description">
            7.The disclosure of bank account information caused by other
            websites linked to the company (such as online banking, etc.) and
            any legal disputes and consequences resulting therefrom have nothing
            to do with this website.
          </div>
          <div class="description">
            8.Any Giftcard8 user (including minor users) who fails to pass the
            certification or suffers any other losses by providing wrong,
            incomplete, or false information to the company has nothing to do
            with this website.
          </div>
          <div class="description">
            9.The ownership of this website and the final interpretation of this
            agreement belong to California Giftcard8 Information Technology Co.,
            Ltd.
          </div>
        </div>
        <div class="team-items">
          <div class="title">Grievance Policy</div>
          <div class="description">
            Giftcard8 believes in providing excellent service and strives to be
            sincere and transparent in its approach to customer service. Your
            confidence in us is of paramount importance to us and we would never
            place our personal growth before your interest.
          </div>
          <div class="description">
            We aim to provide customers with a friendly and convenient
            experience, we sincerely accept all users' complaints and concerns,
            we do everything we can to fix them. Your feedback will make up for
            our shortcomings. Our goal is to satisfy our customers and provide
            excellent service.
          </div>
          <div class="description">
            Customers can give their complaints or concern by using the in-app
            live chat, contact our official WhatsApp number(+8618179849917),or
            sending an email to gcard8business@giftcard8.com.
          </div>
        </div>
        <div class="team-items">
          <div class="title">Acceptance and change of terms</div>
          <div class="description">
            If you do not agree with any of these Terms of Service please do not
            use the Giftcard8 website, app and its services. By using this site
            or any of Giftcard8’s services, you will be deemed to have
            irrevocably agreed to these terms and conditions.
          </div>
          <div class="description">
            We reserve the right to modify these Terms of Service at any time.We
            encourage you to periodically review this page for the latest
            information on our privacy practices. If there are any
            material changes to this privacy notice, we will send you an update
            message as permitted by law.
          </div>
        </div>
        <div class="team-items">
          <div class="title">Contact Details</div>
          <div class="description">
            Email：gcard8business@giftcard8.com.
          </div>
          <div class="description">
            Whatsapp: +8618179849917
          </div>
        </div>
        <div style="text-align: center;font-weight: bold;">This page was last update in June 2022</div>
      </div>
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";

export default defineComponent({
  name: "Team",
  components: { Header, Footer },
  setup() {
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });

    return {};
  },
});
</script>

<style lang="less">
.team-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  .team-content {
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/card_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;
      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }
    .team-list {
      position: relative;
      overflow: hidden;
      margin: 20px 200px;
      padding: 80px;
      text-align: left;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;

      .team-items {
        margin-top: 40px;
        margin-bottom: 20px;
        .title {
          margin-bottom: 20px;
          color: rgba(51, 51, 51, 1);
          font-size: 26px;
        }
        .description {
          line-height: 25px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
